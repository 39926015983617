import React, { useState } from "react";
import styled from "styled-components";
import Carousel from "react-material-ui-carousel";
import NextIcon from "../../assets/icon/nextIcon.svg";
import NextIconBlack from "../../assets/icon/nextIconBlack.svg";
import PrevIcon from "../../assets/icon/prevIcon.svg";
import PrevIconBlack from "../../assets/icon/prevIconBlack.svg";
import Zoom from "../../assets/locations/zoomblack.svg";
import Modal from "react-modal";
import CloseIcon from "../../assets/store/closeicon.svg";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.25)",
    borderRadius: "0px",
    border: "0.5px solid #000000",
    width: "fit-content",
    height: "auto",
    zIndex: "999999",
    padding: "0px !important"
  },
  overlay: {
    backgroundColor: "#00000070",
    zIndex: "9999999"
  }
};

const Container = styled.div`
  position: relative;
  overflow: hidden;
  height: 677px;
  @media only screen and (max-width: 1100px) {
    height: 640px;
  }
  @media only screen and (max-width: 800px) {
    height: 478.75px;
  }
  @media only screen and (max-width: 480px) {
    height: 500px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const Image = styled.img`
  height: 677px;
  width: 100%;
  object-fit: cover;
  @media only screen and (max-width: 1100px) {
    height: 640px;
  }
  @media only screen and (max-width: 800px) {
    height: 478.75px;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    height: 500px;
  }
  /* position: absolute;
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto; */
`;

const ModalImage = styled.img`
  height: 80vh;
  width: auto;
  @media only screen and (max-width: 1200px) {
    height: 80vh;
    width: auto;
  }
  @media only screen and (max-width: 840px) {
    height: auto;
    width: 80vw;
  }
  @media only screen and (max-width: 480px) {
    width: 80vw;
    height: auto;
  }
`;

const Icon = styled.img`
  width: 10px;
  height: 35px;
`;

const NextIconImage = styled.img`
  width: 10px;
  height: 35px;
  margin-right: 20px;
`;

const Button = styled.div`
  width: 10px;
  height: 35px;
  background-color: transparent !important;
`;

const IndicatorIcon = styled.div`
  width: 10px;
  height: 10px;
  background-color: transparent;
  margin: 5px;
`;

const ZoomIcon = styled.img`
  position: absolute;
  width: 20px;
  height: 20px;
  cursor: pointer;
  bottom: 20px;
  right: 20px;
`;

const CloseImage = styled.img`
  position: fixed;
  top: 15px;
  right: 15px;
  cursor: pointer;
`;

export const MaterialCarouselStore = ({ sampleImages, color, margin }) => {
  const [imageSrc, setImageSrc] = useState();
  const [modalOpen, setModalOpen] = useState();
  const handleZoom = src => {
    setModalOpen(true);
    setImageSrc(src);
  };
  return (
    <>
      <Container margin={margin}>
        <Carousel
          IndicatorIcon={<IndicatorIcon />}
          autoPlay={false}
          animation={"slide"}
          indicators={true}
          navButtonsAlwaysVisible={true}
          indicatorIconButtonProps={{
            style: {
              width: "8px",
              height: "8px",
              border:
                color === "black" ? "0.5px solid black" : "0.5px solid black",
              backgroundColor: "transparent",
              borderRadius: "0px",
              margin: margin ? "0px 5px 0px 5px" : "5px 5px 0px 5px",
              bottom: "55px"
            }
          }}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: color === "black" ? "transparent" : "black" // 2
            }
          }}
          fullHeightHover={false} // We want the nav buttons wrapper to only be as big as the button element is
          NavButton={({ onClick, className, style, next, prev }) => {
            if (sampleImages.length !== 1) {
              return (
                <Button onClick={onClick} className={className} style={style}>
                  {next && (
                    <NextIconImage
                      src={color === "black" ? NextIconBlack : NextIcon}
                    />
                  )}
                  {prev && (
                    <Icon src={color === "black" ? PrevIconBlack : PrevIcon} />
                  )}
                </Button>
              );
            }
          }}
        >
          {sampleImages.map((slide, index) => {
            return (
              <ImageContainer>
                <Image src={slide} />
                <ZoomIcon src={Zoom} onClick={() => handleZoom(slide)} />
              </ImageContainer>
            );
          })}
        </Carousel>
      </Container>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <CloseImage src={CloseIcon} onClick={() => setModalOpen(false)} />
        <ModalImage src={imageSrc} />
      </Modal>
    </>
  );
};
