import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { AddToCart } from "../components/AddToCart/AddToCart.js";
import { StoreContext } from "../provider/StoreProvider";
import { Cart } from "../components/Cart/cart";
import {
  StoreWrapper,
  ItemContainer,
  ItemTitle,
  ItemPrice,
  ItemDetailContainer,
  ItemDesc,
  Container,
  DetailContainer,
  EditNumberContainer,
  EditNumberBlock,
  NumberBlock,
  BackToStoreButton,
  YouMayAlsoLike,
  SuggestedItemDetailContainer,
  SuggestedItemDesc,
  Row,
  Col,
  DesktopCol,
  MobileCol,
  ItemDescriptionContainer,
  ItemTitleContainer,
  BlackLine,
  ItemDetailLeftColumn,
  ItemDetailRightColumn,
  BlackLineDesktop,
  SuggestedItemImage,
  SuggestedItemDescContainer,
  SuggestedItemTitle,
  SuggestedItemPrice,
  StoreSection,
  CTAContainer
} from "../components/Product/Product";
import { Grid } from "@material-ui/core";
import { navigate } from "gatsby";
import { Footer } from "../components/footer";
import scrollTo from "gatsby-plugin-smoothscroll";
import { MaterialCarouselStore } from "../components/MaterialCarouselStore";

const EditNumber = ({ quantity, setQuantity, maxLimit5 }) => {
  const handleAdd = () => {
    setQuantity(prev => {
      if (maxLimit5 && prev === 5) {
        return prev;
      }
      return prev + 1;
    });
  };
  const handleReduce = () => {
    setQuantity(prev => {
      if (prev === 0) {
        return prev;
      }
      return prev - 1;
    });
  };

  return (
    <EditNumberContainer>
      <EditNumberBlock onClick={handleReduce}>-</EditNumberBlock>
      <NumberBlock>{quantity}</NumberBlock>
      <EditNumberBlock onClick={handleAdd}>+</EditNumberBlock>
    </EditNumberContainer>
  );
};

const ProductTemplate = ({ pageContext: { product, suggestion } }) => {
  const {
    variants: [initialVariant],
    priceRangeV2,
    title,
    description,
    images: [firstImage]
  } = product;
  const { client } = React.useContext(StoreContext);

  const [variant, setVariant] = React.useState({ ...initialVariant });
  const [quantity, setQuantity] = React.useState(1);
  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant;

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  );

  const checkAvailablity = React.useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        const result =
          fetchedProduct?.variants.filter(
            variant => variant.id === productVariant.storefrontId
          ) ?? [];

        if (result.length > 0) {
          setAvailable(result[0].available);
        }
      });
    },
    [productVariant.storefrontId, client.product]
  );

  // const handleOptionChange = (index, event) => {
  //   const value = event.target.value
  //   if (value === "") {
  //     return
  //   }
  //   const currentOptions = [...variant.selectedOptions]
  //   currentOptions[index] = {
  //     ...currentOptions[index],
  //     value,
  //   }
  //   const selectedVariant = variants.find((variant) => {
  //     return isEqual(currentOptions, variant.selectedOptions)
  //   })
  //   setVariant({ ...selectedVariant })
  // }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId);
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId]);

  // const price = formatPrice(
  //   priceRangeV2.minVariantPrice.currencyCode,
  //   variant.price
  // )

  const handleNavigate = product => {
    navigate(`/store/${product}`);
  };

  // const hasVariants = variants.length > 1
  // const hasImages = images.length > 0
  // const hasMultipleImages = true || images.length > 1

  React.useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    if (typeof window !== undefined) {
      window.addEventListener("resize", appHeight);
    }
    appHeight();
    scrollTo(`#storeRef`);
    return window.removeEventListener("resize", appHeight);
  }, []);

  return (
    <>
      <Layout title={"Product"} siteBgColor={"white"}>
        <SEO
          title="Locations"
          keywords={[`wedding`, `sculpture`, `งานแต่งงาน`, `ตู้sculpture`]}
        />
        <StoreWrapper id="storewrapper">
          <StoreSection id="storeRef" />
          <Container>
            <Row>
              <MobileCol>
                <MaterialCarouselStore
                  sampleImages={product.images.map(image => image.originalSrc)}
                />
              </MobileCol>
              <DesktopCol>
                <MaterialCarouselStore
                  sampleImages={product.images.map(image => image.originalSrc)}
                />
              </DesktopCol>
              <Col style={{ position: "relative" }}>
                <DetailContainer>
                  <ItemTitleContainer>
                    <ItemTitle>{title}</ItemTitle>
                    <ItemPrice>
                      {priceRangeV2.maxVariantPrice.amount} THB
                    </ItemPrice>
                  </ItemTitleContainer>
                  <ItemDescriptionContainer>
                    <ItemDesc>{description}</ItemDesc>
                  </ItemDescriptionContainer>
                  <BlackLine />
                  <ItemDetailContainer>
                    <ItemDetailLeftColumn></ItemDetailLeftColumn>
                    <ItemDetailRightColumn>
                      <EditNumber
                        quantity={quantity}
                        setQuantity={setQuantity}
                        maxLimit5={product.handle === "sculpture-random-camera"}
                      />
                    </ItemDetailRightColumn>
                  </ItemDetailContainer>
                  <BlackLineDesktop />
                  <CTAContainer>
                    <AddToCart
                      variantId={productVariant.storefrontId}
                      quantity={quantity}
                      available={available}
                    />
                    <BackToStoreButton onClick={() => navigate("/store")}>
                      Back to store
                    </BackToStoreButton>
                  </CTAContainer>
                </DetailContainer>
              </Col>
            </Row>
            <YouMayAlsoLike>YOU MAY ALSO LIKE</YouMayAlsoLike>
            <Grid container spacing={0}>
              {suggestion &&
                suggestion.map(({ node }) => (
                  <>
                    <Grid item xs={6} md={6} lg={4} xl={4}>
                      <ItemContainer>
                        <SuggestedItemImage
                          src={node.images[0].originalSrc}
                          onClick={() => handleNavigate(node.handle)}
                        />
                        <SuggestedItemDetailContainer>
                          <SuggestedItemTitle
                            onClick={() => handleNavigate(node.handle)}
                          >
                            {node.title}
                          </SuggestedItemTitle>
                          <SuggestedItemPrice>
                            {node.priceRangeV2.maxVariantPrice.amount} THB
                          </SuggestedItemPrice>
                        </SuggestedItemDetailContainer>
                        <SuggestedItemDescContainer>
                          <SuggestedItemDesc
                            onClick={() => handleNavigate(node.handle)}
                          >
                            {node.description}
                          </SuggestedItemDesc>
                        </SuggestedItemDescContainer>
                      </ItemContainer>
                    </Grid>
                  </>
                ))}
            </Grid>
            <Footer />
          </Container>
        </StoreWrapper>
      </Layout>
      <Cart />
    </>
  );
};

export default ProductTemplate;
