import * as React from "react";
import { StoreContext } from "../../provider/StoreProvider";
import styled from "styled-components";

const Button = styled.button`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 0px;
  color: white !important;
  font-family: "Futura Condensed";
  font-size: 20px;
  margin-top: 20px;
  z-index: 99999;
  :hover {
    color: #ffffff !important;
    box-shadow: 4px 4px 7px rgba(225, 225, 225, 0.9);
  }
`;

export function AddToCart({ variantId, quantity, available, ...props }) {
  const { addVariantToCart, loading } = React.useContext(StoreContext);

  function addToCart(e) {
    e.preventDefault();
    addVariantToCart(variantId, quantity);
  }

  return (
    <Button
      type="submit"
      onClick={addToCart}
      disabled={!available || loading}
      {...props}
    >
      {available ? "Add to cart" : "Out of Stock"}
    </Button>
  );
}
